import React, { Fragment, useState } from 'react';
import { FormDataConsumer } from 'ra-core';
import { List, Datagrid, TextField, BooleanField } from 'ra-ui-materialui';
import {
  Edit,
  Filter,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  NullableBooleanInput,
  TextInput
} from 'ra-ui-materialui';
import Check from '@material-ui/icons/Check';

import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const Filters = props => (
  <Filter {...props}>
    <ReferenceInput source="site" reference="sites" alwaysOn={true}>
      <SelectInput optionText="siteID" />
    </ReferenceInput>
  </Filter>
);

export const WechatRequestList = props => (
  <List
    {...props}
    exporter={false}
    bulkActions={false}
    filters={<Filters />}
    filterDefaultValues={{ by_null: 'true' }}
    filter={{ type_null: false }}
    sort={{ field: 'createdAt', order: 'DESC' }}
  >
    <TabbedDatagrid />
  </List>
);

export const WechatList = props => (
  <List
    {...props}
    basePath="wechatusers"
    resource="wechats"
    exporter={false}
    bulkActions={false}
    filters={<Filters />}
    filter={{ user_null: false }}
    sort={{ field: 'updatedAt', order: 'DESC' }}
  >
    <Datagrid>
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="type" />
      <TextField source="site.siteID" label="Site" />
      <TextField source="email" />
    </Datagrid>
  </List>
);

const WechatEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <NullableBooleanInput source="approved" />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.approved === false && (
            <TextInput source="comment" {...rest} multiline />
          )
        }
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);

const TabbedDatagrid = props => {
  const {
    ids,
    filterValues,
    filterValues: { by_null },
    setFilters
  } = props;
  const tabs = [
    { id: 'true', name: 'Pending requests' },
    { id: 'false', name: 'Approval history' }
  ];
  const [all, setAll] = useState({ true: [], false: [] });
  ids !== all[by_null] && setAll({ ...all, [by_null]: ids });
  const handleChange = (event, value) => {
    setFilters({ ...filterValues, by_null: value });
  };

  return (
    <Fragment>
      <Tabs
        variant="fullWidth"
        centered
        value={by_null}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map(choice => (
          <Tab key={choice.id} label={choice.name} value={choice.id} />
        ))}
      </Tabs>
      <Divider />
      <Datagrid
        {...props}
        ids={all[by_null]}
        rowClick={(id, basePath, record) =>
          record && record.user ? null : 'edit'
        }
      >
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="type" />
        <TextField source="site.siteID" label="Site" />
        <TextField source="email" />
        <BooleanField source="approved" />
      </Datagrid>
    </Fragment>
  );
};

export default {
  icon: Check,
  list: WechatRequestList,
  edit: WechatEdit
};
