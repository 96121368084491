import React from 'react';
import {
  Edit,
  SimpleForm,
  FileInput,
  FileField,
  TextInput,
  ArrayInput,
  SimpleFormIterator
} from 'ra-ui-materialui';
import Toolbar from './components/Toolbar';

const ConfigForm = props => (
  <SimpleForm {...props} toolbar={<Toolbar />}>
    <FileInput source="faq" label="Q&A" accept="application/pdf">
      <FileField source="url" title="name" />
    </FileInput>
    <FileInput source="slotCapability" accept="application/pdf">
      <FileField source="url" title="name" />
    </FileInput>
    <ArrayInput source="TAGuidelines" label="TA-Guideline" fullWidth>
      <SimpleFormIterator>
        <TextInput source="title" fullWidth />
        <TextInput source="url" type="url" fullWidth />
      </SimpleFormIterator>
    </ArrayInput>
  </SimpleForm>
);

export default {
  edit: props => (
    <Edit {...props} redirect={false}>
      <ConfigForm />
    </Edit>
  )
};
