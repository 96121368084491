import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS
} from 'react-admin';

export default async (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username: identifier, password } = params;
    if (identifier && password) {
      const response = await fetch(process.env.REACT_APP_API + '/auth/local', {
        method: 'POST',
        body: JSON.stringify({ identifier, password }),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      });
      if (response.status < 200 || response.status >= 300) {
        return Promise.reject();
      }
      const data = await response.json();
      params = { ...params, ...data };
    }
    const { jwt, user } = params;
    if (jwt) {
      localStorage.setItem('token', jwt);
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('role', user.role.type);
    }
  }
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('role');
    return Promise.resolve();
  }
  if (type === AUTH_ERROR) {
    const status = params.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('role');
      return Promise.reject();
    }
    return Promise.resolve();
  }
  if (type === AUTH_CHECK) {
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
  }
  if (type === AUTH_GET_PERMISSIONS) {
    const role = localStorage.getItem('role') || 'public';
    return role ? Promise.resolve(role) : Promise.reject();
  }
  return Promise.resolve();
};
