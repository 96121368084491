import React, { Fragment } from 'react';
import { AppBar, UserMenu } from 'ra-ui-materialui';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import logo from './logo.png';

const Menu = props => {
  const { email } = JSON.parse(localStorage.getItem('user'));
  return (<Fragment>
    <UserMenu {...props} /> {email}
  </Fragment>);
}

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  spacer: {
    flex: 1
  }
});

const CustomAppBar = props => {
  const classes = useStyles();
  return (
    <AppBar {...props} userMenu={<Menu />}>
      <Typography
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <img src={logo} alt="CARTIFAN-1" height={36} />
      <span className={classes.spacer} />
    </AppBar>
  );
};

export default CustomAppBar;
