import React from "react";
import { SimpleForm, DateInput } from "ra-ui-materialui";
import Toolbar from '../components/Toolbar';

const FollowUpForm = props => (
  <SimpleForm {...props} toolbar={<Toolbar />} >
    <DateInput source="datePDFollowUp" label="PD Date" />
  </SimpleForm>
);

export default FollowUpForm;
