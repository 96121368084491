import React, { Fragment, useState } from 'react';
import { Mutation, usePermissions } from 'ra-core';
import { Button, Confirm } from 'ra-ui-materialui';
import { ArrowForward } from '@material-ui/icons';

const StatusButton = ({ record, status, icon=(<ArrowForward />) }) => {

  const [isOpen, setIsOpen] = useState(false);
  const { permissions } = usePermissions();

  return (['systemadmin', 'schedulingadmin'].includes(permissions) && 
    <Mutation
      type="UPDATE"
      resource="patients"
      payload={{ id: record.id, data: { status: status } }}
      options={{
        onSuccess: {
          refresh: true,
        },
        onError: {
          refresh: true,
        }
      }}
    >
      {(confirm) => (
        <Fragment>
          <Button label={status} onClick={() => setIsOpen(true)} key="button" >
            {icon}
          </Button>
          <Confirm
            isOpen={isOpen}
            title={`Move patient to ${status}`}
            content='Are you sure you want to move this patient?'
            onConfirm={confirm}
            onClose={() => setIsOpen(false)}
            confirm={"Confirm"}
          />
        </Fragment>
      )}
    </Mutation>
  );
}

export default StatusButton;


