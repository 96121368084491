import { fetchUtils } from 'ra-core';
import StrapiDataProvider from "ra-data-strapi";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("token");
  if (token) options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = StrapiDataProvider(process.env.REACT_APP_API, httpClient);

const upload = async ({ rawFile }) => {
  const data = new FormData();
  data.append('files', rawFile);
  const response = await fetch(process.env.REACT_APP_API + '/upload', {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token')
    },
    body: data
  });

  return response.json();
}

const addUploadFeature = requestHandler => async (type, resource, params) => {
  if (type === 'CREATE' || type === 'UPDATE') {
    for (const field of Object.keys(params.data)) {
      if (params.data[field]) {
        if (Array.isArray(params.data[field])) {
          const files = [];
          for (const file of params.data[field]) {
            if (file.rawFile instanceof File) {
              const fs = await upload(file);
              files.push(fs[0]);
            } else {
              files.push(file);
            }
          }
          params.data[field] = files;
        } else if (params.data[field].rawFile instanceof File) {
          const files = await upload(params.data[field]);
          params.data[field] = files[0];
        }

      }
    }
  }

  return requestHandler(type, resource, params);
};

export default addUploadFeature(dataProvider);