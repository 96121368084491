import React from "react";
import { Create, Edit } from "ra-ui-materialui";
import Accessibility from "@material-ui/icons/Accessibility";

import PatientList from "./PatientList";
import WaitlistForm from './WaitlistForm';
import TreatmentForm from "./TreatmentForm";
import FollowUpForm from "./FollowUpForm";

const PatientForm = props => {
  const { record: { status } } = props;
  switch (status) {
    case 'Waitlist':
      return <WaitlistForm {...props} />;
    case 'Treatment':
      return <TreatmentForm {...props} />;
    case 'Follow-up':
      return <FollowUpForm {...props} />;
    default:
      return '';
  }
}

const Title = ({ record }) => {
  return <span>Patients {record ? ` ${record.subjectId || record.trackingId}` : ''}</span>;
};

export default {
  icon: Accessibility,
  list: PatientList,
  edit: props => <Edit title={<Title />} {...props}><PatientForm /></Edit>,
  create: props => <Create {...props}><WaitlistForm /></Create>
};

export const followupDates = ({ doneCARTInfusion, dateCARTInfusion, datePDFollowUp }) => {
  const dates = [];
  if (doneCARTInfusion) {
    if (dateCARTInfusion) {
      [
        1,
        28,
        56,
        78,
        100,
        128,
        156,
        184,
        212,
        240,
        268,
        296,
        324,
        352,
        380,
        408,
        436,
        464,
        492,
        520,
        548,
        576,
        604,
        632,
        660,
        688,
        716,
        744,
        772,
        800,
        828,
        856,
        884,
        912,
        940,
        968,
        996,
        1020,
        1048,
        1076,
        1104
      ].forEach(d => {
        const date = new Date(dateCARTInfusion);
        const end = new Date(datePDFollowUp);
        date.setDate(date.getDate() + d - 1)
        if (!datePDFollowUp || (datePDFollowUp && end > date)) {
          const name = `Day ${d}`;
          const sn = `${d}`;
          dates.push({ date, name, sn });
        }
      })
    }
    if (datePDFollowUp) {
      [1, 128, 256, 384, 512, 640, 768, 896, 1024, 1152].forEach((d, i) => {
        const date = new Date(datePDFollowUp);
        date.setDate(date.getDate() + d - 1)
        const name = `Follow up visit-${i + 1}`;
        const sn = `V${i + 1}`;
        dates.push({ date, name, sn });
      })
    }
  }
  
  return dates;
}
