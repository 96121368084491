import React from 'react';
import { required } from 'ra-core';
import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput
} from 'ra-ui-materialui';
import DateTimeInput from '../components/DateTimeInput';

const WaitlistForm = props => (
  <SimpleForm {...props} redirect="list">
    <ReferenceInput
      source="site"
      label="Site"
      reference="sites"
      validate={[required()]}
    >
      <SelectInput
        optionText={choice => `${choice.siteID} - ${choice.siteName}`}
      />
    </ReferenceInput>
    <TextInput source="age" validate={[required()]} />
    <SelectInput
      source="gender"
      choices={['Male', 'Female'].map(s => ({ id: s, name: s }))}
      validate={[required()]}
    />
    <TextInput source="dateOfMMDiagnosis" label="Date of MM Diagnosis" />
    <TextInput
      source="numberOfPriorLinesOfMMTherapy"
      label="Number of Prior Lines of MM Therapy"
      fullWidth
    />
    <DateTimeInput
      source="receivedTime"
      label="Received Time"
      options={{ format: 'YYYY-MM-DD, HH:mm A', clearable: true }}
      validate={[required()]}
    />
    <BooleanInput source="readyForScreening" label="Ready for Screening" />
    <TextInput source="PDStatus" label="PD Status" />
    <TextInput source="weight" label="weight(kg)" />
    <TextInput source="comment" multiline />
  </SimpleForm>
);

export default WaitlistForm;
