import React from 'react';
import { required } from 'ra-core';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Create,
  SimpleForm,
  TextInput,
  FileInput,
  FileField,
  ImageInput,
  ImageField
} from 'ra-ui-materialui';
import DeleteButton from './components/DeleteButton';

const list = props => (
  <List {...props} exporter={false} bulkActionButtons={false}>
    <Datagrid>
      <TextField source="title" />
      <TextField source="content" />
      <DateField source="createdAt" />
      <DeleteButton />
    </Datagrid>
  </List>
);

const NewsletterForm = (
  <SimpleForm redirect={'list'}>
    <TextInput source="title" validate={[required()]} />
    <TextInput source="content" multiline validate={[required()]} />
    <ImageInput
      source="thumb"
      accept="image/*"
      placeholder={
        <div>
          <p>Drop a picture to upload, or click to select it.</p>
          <em>(Only *.png, *.jp(e)g files less than 2MB will be accepted)</em>
        </div>
      }
      validate={[required()]}
    >
      <ImageField source="url" title="name" />
    </ImageInput>
    <FileInput
      source="pdf"
      accept="application/pdf"
      maxSize={16777216}
      placeholder={
        <div>
          <p>Drop a file to upload, or click to select it.</p>
          <em>(Only *.pdf files less than 16MB will be accepted)</em>
        </div>
      }
      validate={[required()]}
    >
      <FileField source="url" title="name" />
    </FileInput>
  </SimpleForm>
);
const create = props => <Create {...props}>{NewsletterForm}</Create>;

export default {
  list,
  create
};
