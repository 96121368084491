import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  root: ({ color }) => ({
    backgroundColor: color,
    color: 'white',
  })
});

const RoomField = ({ record: { name, color } }) => {
  const classes = useStyles({ color });
  return <Button classes={classes} component="span" size="small">{name}</Button>;
}

export default RoomField;