import React from 'react';
import { useInput, FieldTitle } from 'ra-core';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const DateTimeInput = ({
  label,
  helperText,
  onBlur,
  onChange,
  onFocus,
  options,
  source,
  resource,
  validate,
  ...rest
}) => {
  const {
    id,
    input,
    isRequired,
    meta: { error, touched },
  } = useInput({
    onBlur,
    onChange,
    onFocus,
    resource,
    source,
    validate,
    ...rest,
  });
  const change = date => {
    input.onChange(date);
    input.onBlur();
  };
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DateTimePicker
        {...options}
        label={<FieldTitle
          label={label}
          source={source}
          resource={resource}
          isRequired={isRequired}
        />}
        value={input.value ? input.value : null}
        onChange={date => change(date)}
      />
    </MuiPickersUtilsProvider>
  );
}

export default DateTimeInput;