import React, { useState } from 'react';
import { useGetList } from 'ra-core';
import { Card, CardContent } from '@material-ui/core';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { followupDates } from './';

const PatientCalendar = props => {
  const [status, setStatus] = useState('Treatment');

  const dates = [
    { d: 'ICF' },
    { d: 'Apheresis' },
    { d: 'CART' },
    { d: 'LymphodepletionStart' },
    { d: 'CARTInfusion' }
  ];
  const { data, ids } = useGetList(
    'patients',
    { page: 1, perPage: 100000 },
    { field: 'createdAt', order: 'DESC' },
    { status }
  );
  const events = [];
  if (ids) {
    if (status === 'Treatment') {
      ids.forEach(id => {
        const pid = data[id]['subjectId'] || data[id]['trackingId'];
        const { slot } = data[id];
        if (slot) {
          events.push({
            allDay: true,
            start: slot['manufactureDay0'],
            title: `${pid}<br/><em>Manufacture Day0</em>`,
          });
        }
        dates.forEach(({ d }) =>
          events.push({
            allDay: true,
            start: data[id][`date${d}`],
            title: `${pid}<br/><em>${d}</em>`,
          })
        );
      });
    }

    if (status === 'Follow-up') {
      ids.forEach(id => {
        const pid = data[id]['subjectId'] || data[id]['trackingId'];
        const dates = followupDates(data[id]);
        dates.forEach(({ date, name }) =>
          events.push({
            allDay: true,
            start: date,
            title: `${pid}<br/><em>${name}</em>`,
          })
        );
      });
    }
  }
  return (
    <Card>
      <Tabs
        variant="fullWidth"
        centered
        value={status}
        indicatorColor="primary"
        onChange={(event, value) => setStatus(value)}
      >
        {['Treatment', 'Follow-up'].map(status => (
          <Tab key={status} label={status} value={status} />
        ))}
      </Tabs>
      <CardContent>
        <FullCalendar
          defaultView="dayGridMonth"
          fixedWeekCount={false}
          header={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,dayGridWeek'
          }}
          plugins={[dayGridPlugin]}
          events={events}
          eventLimit={true}
          eventRender={({ el }) => {
            const title = el.getElementsByClassName('fc-title').item(0);
            title.innerHTML = title.innerText;
          }}
        />
      </CardContent>
    </Card>
  );
};

export default PatientCalendar;
