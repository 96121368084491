import React from 'react';
import { usePermissions } from 'ra-core';
import { EditButton } from 'ra-ui-materialui';

const EditBtn = props => {
  const { permissions } = usePermissions();
  return (['systemadmin', 'schedulingadmin', 'slotadmin'].includes(permissions) && <EditButton {...props} />);
};

export default EditBtn;


