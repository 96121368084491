import React from "react";
import { List, Datagrid, TextField, DateField } from "ra-ui-materialui";
import { Filter, SelectInput } from "ra-ui-materialui";
import People from "@material-ui/icons/People";

const Filters = props => (
  <Filter {...props}>
    <SelectInput
      source="role.type"
      label="Role"
      choices={[
        "authenticated",
        "schedulingadmin",
        "slotadmin",
        "systemadmin"
      ].map(s => ({ id: s, name: s }))}
    />
  </Filter>
);

const UserList = props => (
  <List {...props} exporter={false} bulkActionButtons={false} filters={<Filters />} filter={{provider_ne: 'wechat'}}>
    <Datagrid>
      <TextField source="username" />
      <TextField source="email" />
      <TextField source="role.name" label="Role" />
      <DateField source="updatedAt" label="Last login time" showTime />
    </Datagrid>
  </List>
);

export default {
  icon: People,
  list: UserList
};
