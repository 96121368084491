import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Create,
  SimpleForm,
  TextInput
} from 'ra-ui-materialui';
import DeleteButton from './components/DeleteButton';

const list = props => (
  <List {...props} exporter={false} bulkActionButtons={false}>
    <Datagrid>
      <TextField source="title" />
      <TextField source="content" />
      <DateField source="createdAt" />
      <DeleteButton />
    </Datagrid>
  </List>
);

const NewsForm = (
  <SimpleForm redirect={'list'}>
    <TextInput source="title" />
    <TextInput source="content" multiline />
  </SimpleForm>
);
const create = props => <Create {...props}>{NewsForm}</Create>;

export default {
  list,
  create
};
