import React from "react";
import { Route } from 'react-router-dom';
import { Admin, Resource } from "react-admin";
import { Layout } from "ra-ui-materialui";
import AppBar from "./layout/AppBar";
import Menu from "./layout/Menu";
import Login from "./layout/Login";

import "./App.css";

import authProvider from "./authProvider";
import dataProvider from "./dataProvider";

import patients from "./patients";
import PatientCalendar from './patients/PatientCalendar';
import slots from "./slots";
import wechats, { WechatList } from "./wechats";
import users from "./users";
import sites from "./sites";
import studyprotocols from "./studyprotocols";
import announcements from "./announcements";
import newsletters from "./newsletters";
import configs from './configs';

const customRoutes = [
  <Route exact path="/patients/calendar" component={PatientCalendar} />,
  <Route exact path="/wechatusers" component={WechatList} />
];

const App = () => (
  <Admin
    customRoutes={customRoutes}
    authProvider={authProvider}
    dataProvider={dataProvider}
    layout={props => <Layout {...props} appBar={AppBar} menu={Menu} />}
    loginPage={Login}
  >
    {permissions => {
      const cd = ['systemadmin', 'schedulingadmin', 'slotadmin'].includes(permissions) ? {} : { create: null, edit: null };
      const slotcd = ['systemadmin', 'slotadmin'].includes(permissions) ? {} : { create: null, edit: null };
      return [
        <Resource name="patients" {...patients} {...cd} />,
        <Resource name="slots" {...slots} {...slotcd} />,
        permissions === 'systemadmin' ? <Resource name="wechats" {...wechats} /> : null,
        permissions === 'systemadmin' ? <Resource name="users" {...users} /> : null,
        <Resource name="announcements" {...announcements} {...cd} />,
        <Resource name="newsletters" {...newsletters} {...cd} />,
        <Resource name="studyprotocols" {...studyprotocols} {...cd} />,
        <Resource name="sites" {...sites} />,
        <Resource name="configs" {...configs} {...cd} />
      ];
    }}
  </Admin>
);

export default App;
