import React from 'react';
import { List, Datagrid, TextField } from 'ra-ui-materialui';
import LocalHospital from '@material-ui/icons/LocalHospital';

const SiteList = props => (
  <List
    {...props}
    exporter={false}
    bulkActionButtons={false}
    sort={{ field: 'siteID', order: 'ASC' }}
  >
    <Datagrid>
      <TextField source="siteID" label="ID" />
      <TextField source="siteName" label="Name" />
    </Datagrid>
  </List>
);

export default {
  icon: LocalHospital,
  list: SiteList
};
