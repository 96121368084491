import React from 'react';
import { Login } from 'ra-ui-materialui';
import { Button, Box } from '@material-ui/core';
import logo from './logo.png';

const JNJLogin = () => {
  return (
    <Login>
      <Box display="flex" justifyContent="center"><img src={logo} alt="CARTIFAN-1" /></Box>
      <Box display="flex" justifyContent="center" m={3}>
        <Button
          variant="contained"
          color="primary"
          href="/connect/jnj"
        >
          Sign In
        </Button>
      </Box>
    </Login>
  );
};

export default JNJLogin;
