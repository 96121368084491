import React from 'react';
import get from 'lodash/get';
import pure from 'recompose/pure';
import Typography from '@material-ui/core/Typography';

const toLocaleStringSupportsLocales = (() => {
  try {
    new Date().toLocaleString('i');
  } catch (error) {
    return error instanceof RangeError;
  }
  return false;
})();


export const DateField = ({
  className,
  locales,
  options,
  record,
  showTime = false,
  source,
  days = 0,
  ...rest
}) => {
  if (!record) {
    return null;
  }
  const value = get(record, source);
  if (value == null) {
    return null;
  }
  const date = value instanceof Date ? value : new Date(value);
  date.setDate(date.getDate() + days);
  const dateString = showTime
    ? toLocaleStringSupportsLocales
      ? date.toLocaleString(locales, options)
      : date.toLocaleString()
    : toLocaleStringSupportsLocales
      ? date.toLocaleDateString(locales, options)
      : date.toLocaleDateString();

  return (
    <Typography
      component="span"
      variant="body2"
      className={className}
      {...rest}
    >
      {dateString}
    </Typography>
  );
};

const EnhancedDateField = pure(DateField);

EnhancedDateField.defaultProps = {
  addLabel: true,
};

EnhancedDateField.displayName = 'EnhancedDateField';

export default EnhancedDateField;
