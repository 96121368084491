import React, { Fragment, useState } from 'react';

import { List, Datagrid, TextField, DateField, BooleanField } from 'ra-ui-materialui';
import { Filter, ReferenceInput, SelectArrayInput } from 'ra-ui-materialui';

import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

import { ArrowBack } from '@material-ui/icons';

import EditButton from './EditButton';
import StatusButton from './StatusButton';
import { followupDates } from './';

const PatientFilters = props => (
  <Filter {...props}>
    <ReferenceInput source="site" reference="sites" alwaysOn >
      <SelectArrayInput optionText="siteID" />
    </ReferenceInput>
  </Filter>
);

const FollowUpEditButton = ({ record, ...rest }) =>
  record && record.doneCARTInfusion ? (<EditButton record={record} {...rest} />) : null;

const FollowUpDates = ({ record }) => {
  const dates = followupDates(record);
  const now = new Date();
  let next = '';
  return dates.map(({ date, sn }) => {
    const d = new Date(date);
    if (!next && d > now)  {
      next = d;
    }
    return <Chip label={date.toLocaleDateString()} avatar={<Avatar>{sn}</Avatar>}  color={next === d ? 'primary' : ''}/>;
  });
};

const TabbedDatagrid = props => {
  const { ids, filterValues, filterValues: { status }, setFilters } = props;
  const tabs = ['Waitlist', 'Treatment', 'Follow-up', 'All'];
  const [all, setAll] = useState({ Waitlist: [], Treatment: [], 'Follow-up': [] });
  if (ids !== all[status]) {
    setAll({ ...all, [status]: ids });
  }
  const handleChange = (event, value) => {
    value = value === 'All' ? '' : value;
    setFilters({ ...filterValues, status: value });
  };

  return (
    <Fragment>
      <Tabs variant="fullWidth" centered value={status || 'All'} indicatorColor="primary" onChange={handleChange}>
        {tabs.map(status => (
          <Tab key={status} label={status} value={status} />
        ))}
      </Tabs>
      <Divider />
      <Datagrid {...props} ids={all[status]} expand={status === 'Follow-up' ? <FollowUpDates /> : false}>
        {status === "Waitlist" || <TextField source="subjectId" label="Subject ID" />}
        <TextField source="trackingId" label="Tracking Number" />
        <TextField source="age" />
        {status === "Waitlist" && [
          <TextField source="dateOfMMDiagnosis" label="Date of MM Diagnosis" />,
          <TextField source="numberOfPriorLinesOfMMTherapy" label="Number of Prior Lines of MM Therapy" />,
          <TextField source="ranking" />,
          <DateField source="receivedTime" label="Received Time" showTime />,
          <BooleanField source="readyForScreening" label="Ready for Screening" />,
          <TextField source="PDStatus" label="PD Status" />
        ]}
        {status === "Treatment" && [
          <TextField source="slot.room" label="Room" />,
          <DateField source="slot.manufactureDay0" label="Manufacture Day0" />
        ]}
        {status === "Follow-up" && [
          <TextField source="statusFollowUp" label="Status" />,
          <DateField source="datePDFollowUp" label="PD Date" />,

        ]}
        {status === "Waitlist" && [
          <EditButton />,
          <StatusButton status="Treatment" />
        ]}
        {status === "Treatment" && [
          <EditButton />,
          <StatusButton status="Waitlist" icon={<ArrowBack />} />,
          <StatusButton status="Follow-up" />,

        ]}
        {status === "Follow-up" && [
          <FollowUpEditButton />,
          <StatusButton status="Treatment" icon={<ArrowBack />} />
        ]}
      </Datagrid>
    </Fragment>
  );
}

const PatientList = props => (
  <List
    {...props}
    bulkActions={false}
    filters={<PatientFilters />}
    filterDefaultValues={{ status: "Waitlist" }}
    sort={{ field: 'ranking', order: 'ASC' }}
  >
    <TabbedDatagrid />
  </List>
);

export default PatientList;
