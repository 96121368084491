import React from 'react';
import { FormDataConsumer, useGetList, useGetOne } from 'ra-core';
import {
  TabbedForm,
  FormTab,
  TextInput,
  DateInput,
  ReferenceInput,
  ReferenceField,
  SelectInput,
  BooleanInput,
  FileInput,
  FileField
} from 'ra-ui-materialui';
import DateField from '../components/DateField';
import Toolbar from '../components/Toolbar';

const SlotInput = props => {
  const { record, source, reference } = props;
  const { data, ids } = useGetList(
    reference,
    { page: 1, perPage: 1000 },
    { field: 'createAt', order: 'DESC' },
    { patient_null: true }
  );
  const choices = ids.map(id => data[id]);
  const { data: d } = useGetOne(reference, record[source]);
  d && choices.unshift(d);

  return (
    <SelectInput {...props} allowEmpty emptyValue={null} choices={choices} />
  );
};

const DateCompleted = ({ source, label, ...rest }) => {
  return [
    <FormDataConsumer>
      {({ formData, ...r }) => (
        <DateInput
          source={`date${source}`}
          {...rest}
          {...r}
          label={label}
          disabled={formData[`done${source}`]}
        />
      )}
    </FormDataConsumer>,
    <BooleanInput source={`done${source}`} {...rest} label="Completed" />
  ];
};

const TreatmentForm = props => (
  <TabbedForm {...props} toolbar={<Toolbar />}>
    <FormTab label="Patient Identification">
      <SlotInput
        source="slot"
        label="Slot"
        reference="slots"
        optionText={choice =>
          `Room ${choice.room} - ${new Date(
            choice.manufactureDay0
          ).toLocaleDateString()}`
        }
      />
      <ReferenceInput source="site" label="Site" reference="sites">
        <SelectInput
          optionText={choice => `${choice.siteID} - ${choice.siteName}`}
        />
      </ReferenceInput>
      <TextInput source="age" />
      <SelectInput
        source="gender"
        choices={['Male', 'Female'].map(s => ({ id: s, name: s }))}
      />
      <TextInput source="dateOfMMDiagnosis" label="Date of MM Diagnosis" />
      <TextInput
        source="numberOfPriorLinesOfMMTherapy"
        label="Number of Prior Lines of MM Therapy"
        fullWidth
      />
      <TextInput source="weight" label="weight(kg)" />
      <TextInput source="comment" multiline />
    </FormTab>
    <FormTab label="Slot Reservation">
      <FileInput
        source="slotReservationForm"
        multiple={true}
        accept="application/pdf"
        maxSize={16777216}
        placeholder={
          <div>
            <p>Drop a file to upload, or click to select it.</p>
            <em>(Only *.pdf files less than 16MB will be accepted)</em>
          </div>
        }
      >
        <FileField source="url" title="name" />
      </FileInput>
      <TextInput source="subjectId" label="Subject ID" />
      <DateCompleted source="ICF" label="Planned/actual ICF date" />
      <ReferenceField
        {...props}
        label="Latest apheresis date (Manufacture Day0 - 3)"
        source="slot"
        reference="slots"
        linkType={false}
      >
        <DateField source="manufactureDay0" days={-3} />
      </ReferenceField>
      <DateCompleted source="Apheresis" label="Planned/actual apheresis date" />
      {/* <DateField source="slot.manufactureDay0" days={25} label="Release Testing Day 15 (Manufacture Day0 + 25)" /> */}
      {/* <DateField source="slot.manufactureDay0" days={27} label="QA Review Day 2 (Manufacture Day0 + 27)" /> */}
      <ReferenceField
        {...props}
        label="Earliest CAR-T release date (Manufacture Day0 +28)"
        source="slot"
        reference="slots"
        linkType={false}
      >
        <DateField source="manufactureDay0" days={28} />
      </ReferenceField>
      <DateCompleted
        source="CART"
        label="Planned/actual CAR-T release date"
        style={{ width: 320 }}
      />
    </FormTab>
    <FormTab label="Bridging Therapy">
      <FileInput
        source="bridgingTherapyRegimen"
        multiple={true}
        accept="application/pdf"
        maxSize={16777216}
        placeholder={
          <div>
            <p>Drop a file to upload, or click to select it.</p>
            <em>(Only *.pdf files less than 16MB will be accepted)</em>
          </div>
        }
      >
        <FileField source="url" title="name" />
      </FileInput>
      <BooleanInput source="bridgingTherapyNeeded" />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.bridgingTherapyNeeded && (
            <TextInput
              source="dateBridgingTherapyStart"
              label="Planned bridging therapy start date"
              {...rest}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.bridgingTherapyNeeded && (
            <TextInput
              source="dateBridgingTherapyEnd"
              label="Planned bridging therapy end date"
              {...rest}
            />
          )
        }
      </FormDataConsumer>
      <BooleanInput source="secondApheresisNeeded" />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.secondApheresisNeeded && (
            <TextInput
              source="dateOf2ndApheresis"
              label="Planned date of 2nd apheresis"
              {...rest}
            />
          )
        }
      </FormDataConsumer>
    </FormTab>
    <FormTab label="Study Treatment">
      <DateCompleted
        source="LymphodepletionStart"
        label="Planned/actual lymphodepletion start date"
        style={{ width: 320 }}
      />
      <DateCompleted
        source="CARTInfusion"
        label="Planned/actual CAR-T infusion date"
        style={{ width: 320 }}
      />
    </FormTab>
  </TabbedForm>
);

export default TreatmentForm;
