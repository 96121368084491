import React from 'react';
import { useListController, downloadCSV } from 'ra-core';
import {
  ListView,
  Create,
  Edit,
  Filter,
  SimpleForm,
  DateInput,
  CheckboxGroupInput,
  SelectInput
} from 'ra-ui-materialui';
import jsonExport from 'jsonexport/dist';
import { CardContent } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';

import RoomField from './RoomField';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import blueGrey from '@material-ui/core/colors/blueGrey';
import { makeStyles } from '@material-ui/styles';

const rooms = [
  { id: 'N-1', color: '#2176AE' },
  { id: 'N-2', color: '#72C7EC' },
  { id: 'N-3', color: '#B66D0D' },
  { id: 'N-4', color: '#FBB13C' },
  { id: 'Cryo Room', color: red[500] },
  { id: 'Room TBD-1', color: grey[500] },
  { id: 'Room TBD-2', color: blueGrey[500] }
];

const roomStyles = {};
rooms.forEach(({ id, color }) => {
  roomStyles[id] = {
    borderColor: color,
    backgroundColor: color
  };
});

const useStyles = makeStyles({
  ...roomStyles,
  empty: {
    backgroundColor: 'transparent'
  }
});

export const SlotView = ({ ids, data, total, loadedOnce }) => {
  const classes = useStyles();
  const events = ids.map(i => {
    const { id, room, manufactureDay0, patient } = data[i];
    const date = new Date(manufactureDay0);
    date.setDate(date.getDate() + 11);
    return {
      id: id,
      groupId: room,
      allDay: true,
      url: patient ? '' : `/#/slots/${id}`,
      start: manufactureDay0,
      end: date,
      title: patient ? patient.subjectId || patient.trackingId : '',
      classNames: [classes[room], patient ? '' : classes.empty]
    };
  });

  return (
    <CardContent>
      <FullCalendar
        defaultView="dayGridMonth"
        fixedWeekCount={false}
        header={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek'
        }}
        plugins={[dayGridPlugin]}
        events={events}
        eventLimit={true}
      />
    </CardContent>
  );
};

const Filters = props => (
  <Filter {...props}>
    <CheckboxGroupInput
      source="room"
      label=""
      choices={rooms.map(({ id, color }) => ({ id, name: `${id}`, color }))}
      optionText={<RoomField />}
      alwaysOn
    />
  </Filter>
);

const exporter = slots => {
  const roomSlot = {};
  rooms.forEach(({ id }) => (roomSlot[id] = {}));
  const daysSlot = {};
  let start = new Date();
  let end = new Date();
  slots.forEach(({ room, manufactureDay0 }) => {
    const date = new Date(manufactureDay0);
    start = start < date ? start : date;
    end = end > date ? end : date;
    [...Array(11).keys()].forEach(i => {
      const date = new Date(manufactureDay0);
      date.setDate(date.getDate() + i);
      roomSlot[room][`${date.getMonth() + 1}-${date.getDate()}`] = `day${i}`;
    });
  });
  do {
    daysSlot[`${start.getMonth() + 1}-${start.getDate()}`] = '';
    start.setDate(start.getDate() + 1);
  } while (start <= end);
  const json = rooms.map(({ id }) => ({
    room: id,
    ...daysSlot,
    ...roomSlot[id]
  }));

  jsonExport(json, (err, csv) => downloadCSV('\ufeff' + csv, 'slots'));
};

const SlotList = props => {
  const controllerProps = useListController(props);
  return (
    <ListView
      {...controllerProps}
      {...props}
      filters={<Filters />}
      exporter={exporter}
      pagination={false}
    >
      <SlotView />
    </ListView>
  );
};

const SlotForm = (
  <SimpleForm redirect={'list'}>
    <SelectInput
      source="room"
      choices={rooms.map(({ id }) => ({ id, name: id }))}
    />
    <DateInput source="manufactureDay0" />
  </SimpleForm>
);
const SlotCreate = props => <Create {...props}>{SlotForm}</Create>;
const SlotEdit = props => <Edit {...props}>{SlotForm}</Edit>;

export default {
  icon: CalendarToday,
  list: SlotList,
  create: SlotCreate,
  edit: SlotEdit,
  options: {
    label: 'Slot Scheduling'
  }
};
