import React from "react";
import { required } from "ra-core";
import {
  List,
  Datagrid,
  TextField,
  FileField,
  ArrayField,
  SingleFieldList,
  ChipField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  FileInput,
  ReferenceArrayInput,
  SelectArrayInput
} from "ra-ui-materialui";
import DeleteButton from './components/DeleteButton';

const SPList = props => (
  <List {...props} exporter={false}>
    <Datagrid rowClick={"edit"}>
      <TextField source="title" />
      <FileField source="file.url" title="file.name" label="File" />
      <ArrayField source="sites">
        <SingleFieldList>
          <ChipField source="siteName" />
        </SingleFieldList>
      </ArrayField>
      <DeleteButton />
    </Datagrid>
  </List>
);
const SPForm = (
  <SimpleForm redirect={"list"}>
    <TextInput source="title" validate={[required()]} />
    <FileInput source="file" accept="application/pdf" maxSize={16777216} validate={[required()]} placeholder={<div><p>Drop a file to upload, or click to select it.</p><em>(Only *.pdf files less than 16MB will be accepted)</em></div>} >
      <FileField source="url" title="name" />
    </FileInput>
    <ReferenceArrayInput source="sites" label="Sites" reference="sites" validate={[required()]} fullWidth={true}>
      <SelectArrayInput optionText="siteName" />
    </ReferenceArrayInput>
  </SimpleForm>
);
const SPCreate = props => <Create {...props}>{SPForm}</Create>;
const SPEdit = props => <Edit {...props}>{SPForm}</Edit>;

export default {
  list: SPList,
  create: SPCreate,
  edit: SPEdit,
  options: {
    label: 'Study Protocols'
  }
};
