import React, { useState } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { usePermissions } from 'ra-core';
import { MenuItemLink, Responsive } from 'ra-ui-materialui';
import DefaultIcon from '@material-ui/icons/ViewList';
import { Accessibility, CalendarToday, CalendarViewDay, People, Check, LibraryBooks, Announcement, Email, LocalHospital, Settings } from "@material-ui/icons";

import SubMenu from './SubMenu';

const Menu = ({ onMenuClick, open, logout }) => {
  const { permissions } = usePermissions();
  const [state, setState] = useState({
    menuUsers: false
  });

  const handleToggle = menu => {
    setState(state => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <div>
      <MenuItemLink to="/patients" primaryText="Patients" leftIcon={<Accessibility />} onClick={onMenuClick} sidebarIsOpen={open} />
      <MenuItemLink to="/patients/calendar" primaryText="Patients(Calendar)" leftIcon={<CalendarToday />} onClick={onMenuClick} sidebarIsOpen={open} />
      <MenuItemLink to="/slots" primaryText="Slot Scheduling" leftIcon={<CalendarViewDay />} onClick={onMenuClick} sidebarIsOpen={open} />
      {permissions === 'systemadmin' ? <SubMenu handleToggle={() => handleToggle('menuUsers')} isOpen={state.menuUsers} sidebarIsOpen={open} name="User Management" icon={<People />}>
        <MenuItemLink to="/wechats" primaryText="Pending Request" leftIcon={<Check />} onClick={onMenuClick} sidebarIsOpen={open} />
        <MenuItemLink to="/wechatusers" primaryText="WeChat User" leftIcon={<DefaultIcon />} onClick={onMenuClick} sidebarIsOpen={open} />
        <MenuItemLink to="/users" primaryText="System Admin" leftIcon={<People />} onClick={onMenuClick} sidebarIsOpen={open} />
      </SubMenu> : null}

      <MenuItemLink to="/studyprotocols" primaryText="Study Protocols" leftIcon={<LibraryBooks />} onClick={onMenuClick} sidebarIsOpen={open} />
      <MenuItemLink to="/announcements" primaryText="Announcements" leftIcon={<Announcement />} onClick={onMenuClick} sidebarIsOpen={open} />
      <MenuItemLink to="/newsletters" primaryText="Newsletters" leftIcon={<Email />} onClick={onMenuClick} sidebarIsOpen={open} />
      <MenuItemLink to="/sites" primaryText="Sites" leftIcon={<LocalHospital />} onClick={onMenuClick} sidebarIsOpen={open} />
      {['systemadmin', 'schedulingadmin'].includes(permissions) ? <MenuItemLink to="/configs/cartifan1" primaryText="Others" leftIcon={<Settings />} onClick={onMenuClick} sidebarIsOpen={open} /> : null}
      <Responsive xsmall={logout} medium={null} />
    </div>
  );
};

const mapStateToProps = state => ({
  open: state.admin.ui.sidebarOpen
});

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    {}
  )
);

export default enhance(Menu);